import React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/layout/Layout';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <div className='bg-transparent'>
          <div className='max-w-7xl mx-auto px-4 mt-4'>
            <div className='py-6'>
              <h1 className='text-lg text-yellow-700 font-semibold tracking-wide uppercase text-center'>
                contact us
              </h1>
              <h2 className='mt-2 text-6xl font-extrabold tracking-tight text-white text-center'>
                contact
              </h2>
              <h3 className='mt-4 max-w-2xl mx-auto text-2xl font-normal text-gray-400 text-center'>
                Fill out the form below and we will respond as soon as possible.
              </h3>
              <div className='mt-8 mx-auto max-w-3xl px-5 bg-white shadow-2xl rounded-3xl'>
                <div className='ml-4 mr-4'>
                  <form
                    name='contact'
                    method='post'
                    action='/contact/thanks/'
                    data-netlify='true'
                    data-netlify-honeypot='bot-field'
                    onSubmit={this.handleSubmit}
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type='hidden' name='form-name' value='contact' />
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name='bot-field' onChange={this.handleChange} />
                      </label>
                    </div>
                    <div className='py-2 pt-6'>
                      <label
                        className='text-4xl font-extrabold tracking-tight text-gray-700 text-left'
                        htmlFor={'name'}
                      >
                        your name
                      </label>
                      <div className='py-2'>
                        <input
                          className='appearance-none rounded-none w-full px-3 py-3 border border-gray-700 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-yellow-700 focus:border-yellow-700 focus:z-10'
                          type={'text'}
                          name={'name'}
                          onChange={this.handleChange}
                          id={'name'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className='py-2'>
                      <label
                        className='text-4xl font-extrabold tracking-tight text-gray-700 text-left'
                        htmlFor={'email'}
                      >
                        email
                      </label>
                      <div className='py-2'>
                        <input
                          className='appearance-none rounded-none w-full px-3 py-3 border border-gray-700 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-yellow-700 focus:border-yellow-700 focus:z-10'
                          type={'email'}
                          name={'email'}
                          onChange={this.handleChange}
                          id={'email'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className='py-2'>
                      <label
                        className='text-4xl font-extrabold tracking-tight text-gray-700 text-left'
                        htmlFor={'email'}
                      >
                        subject
                      </label>
                      <div className='py-2'>
                        <input
                          className='appearance-none rounded-none w-full px-3 py-3 border border-gray-700 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-yellow-700 focus:border-yellow-700 focus:z-10'
                          type={'subject'}
                          name={'subject'}
                          onChange={this.handleChange}
                          id={'subject'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className='py-2'>
                      <label
                        className='text-4xl font-extrabold tracking-tight text-gray-700 text-left'
                        htmlFor={'message'}
                      >
                        message
                      </label>
                      <div className='py-2'>
                        <textarea
                          className='appearance-none rounded-none w-full px-3 py-3 border border-gray-700 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-yellow-700 focus:border-yellow-700 focus:z-10'
                          name={'message'}
                          onChange={this.handleChange}
                          id={'message'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className='py-3 pb-8'>
                      <button
                        className='w-full flex justify-center py-3 px-4 text-xl font-semibold border border-transparent rounded-md text-white bg-yellow-700 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                        type='submit'
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
